@import url("https://fonts.googleapis.com/css2?family=Homenaje&family=Quattrocento:wght@400;700&family=Trocchi&display=swap");
@import url(./reset.css);
@import url(styles/header.css);
@import url(styles/nav.css);
@import url(styles/footer.css);
@import url(styles/mission.css);
@import url(styles/tours.css);
@import url(styles/dates.css);
@import url(styles/contact.css);

.App {
  text-align: center;
  overflow-x: hidden;
}

h1 {
  color: #7da563;
  font-size: 100px;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
  font-family: Trocchi;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px;
}

h2 {
  color: #f4e3c6;
  text-align: center;
  text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
  font-family: Trocchi;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h3 {
  font-family: "Homenaje", sans-serif;
  padding: 20px;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
  font-size: 2em;
  color: #f4e3c6;
}

h4 {
  font-family: "Homenaje", sans-serif;
  padding: 20px;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
  font-size: 1em;
  color: #f4e3c6;
}

p {
  font-family: "Homenaje", sans-serif;
  font-size: 1.5em;
  color: #f4e3c6;
  padding: 20px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
  text-align: left;
}

.foreground {
  width: 100vw;
  transition: transform 0.5s ease-out;
}

.foreground.parallax {
  transform: translateY(-10%);
}

button {
  border-radius: 8px;
  border: none;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.8));
  background-color: #f4e3c6;
  color: #2eaa87;
  font-family: "Trocchi", serif;
  padding: 20px 30px;
  z-index: 3;
  font-size: 1.5em;
  height: fit-content;
  white-space: nowrap;
  transition: transform 0.2s ease-out;
}

button:hover {
  border: solid #2eaa87 5px;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.8));
  cursor: pointer;
}

.ready-button {
  margin: 0 auto;
}

.container {
  margin-top: -500px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 50px;
}

.container > div:nth-child(1) {
  grid-column: 3;
  grid-row: 3;
}
.container > img:nth-child(2) {
  grid-column: 1/6;
  grid-row: 1;
}
.container > div:nth-child(3) {
  grid-column: 2/5;
  grid-row: 7/15;
}

.container > div:nth-child(4) {
  grid-column: 1/6;
  grid-row-start: 19;
}

.container > div:nth-child(5) {
  grid-column: 2/5;
  grid-row-start: 105;
}

.container > div:nth-child(6) {
  grid-column: 1/6;
  grid-row-start: 122;
}

.caption {
  font-family: "Homenaje", sans-serif;
  padding: 10px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.8);
  font-size: 12px;
  color: #f4e3c6;
  text-align: center;
}

a:link {
  text-decoration: none;
  font-family: "Homenaje", sans-serif;
  color: #307261;
  cursor: pointer;
}

a:visited {
  text-decoration: none;
  color: #307261;
}

a:hover {
  text-decoration: none;
  color: #2eaa87;
}

a:active {
  text-decoration: none;
}

@media (max-width: 900px) {
  h1 {
    font-size: 80px;
  }

  .container > div:nth-child(5) {
    grid-row-start: 115;
  }

  .container > div:nth-child(6) {
    grid-row-start: 132;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 50px;
  }

  p {
    font-size: 20px;
  }

  .container > div:nth-child(4) {
    grid-column: 1/6;
    grid-row-start: 17;
  }
}

@media (max-width: 600px) {
  .container > div:nth-child(4) {
    grid-row-start: 20;
  }
  .container > div:nth-child(1) {
    grid-column: 3;
    grid-row: 5;
  }
  .container > img:nth-child(2) {
    grid-column: 1/6;
    grid-row: 5;
  }

  .container > div:nth-child(3) {
    grid-column: 2/5;
    grid-row: 9/17;
  }

  .container > div:nth-child(5) {
    grid-row-start: 95;
  }

  .container > div:nth-child(6) {
    grid-row-start: 112;
  }
}

@media (max-width: 500px) {
  .caption {
    font-size: 8px;
  }

  .container > div:nth-child(1) {
    grid-column: 3;
    grid-row: 6;
  }
  .container > img:nth-child(2) {
    grid-column: 1/6;
    grid-row: 5;
  }

  .container > div:nth-child(4) {
    grid-row-start: 20;
  }

  .container > div:nth-child(5) {
    grid-row-start: 138;
  }

  .container > div:nth-child(6) {
    grid-row-start: 155;
  }
}

@media (max-width: 400px) {
  .caption {
    font-size: 8px;
  }

  .container > div:nth-child(1) {
    grid-column: 3;
    grid-row: 6;
  }
  .container > img:nth-child(2) {
    grid-column: 1/6;
    grid-row: 5;
  }

  .container > div:nth-child(4) {
    grid-row-start: 25;
  }

  .container > div:nth-child(5) {
    grid-row-start: 143;
  }

  .container > div:nth-child(6) {
    grid-row-start: 159;
  }
}

:root {
  --spacing-xs: 8px;
  --spacing-sm: 16px;
  --spacing-md: 24px;
  --spacing-lg: 32px;
  --spacing-xl: 48px;
}
