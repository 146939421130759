.moon-sweep {
  display: flex;
  max-width: 1200px;
  gap: 20px;
  margin: 0 auto;
  justify-content: center;
}

.moon-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.moon {
  height: 60px;
  width: 60px;
}

@media (max-width: 900px) {
  .moon {
    height: 40px;
    width: 40px;
  }

  .moon-sweep {
    max-width: 900px;
    gap: 10px;
  }
}

@media (max-width: 700px) {
  .moon {
    height: 20px;
    width: 20px;
  }

  .moon-sweep {
    max-width: 900px;
    gap: 5px;
  }
}

@media (max-width: 500px) {
  .moon {
    height: 15px;
    width: 15px;
  }

  .moon-sweep {
    max-width: 900px;
    gap: 1px;
  }
}
