.contact {
  color: #2eaa87;
  justify-self: center;
  display: grid;
  grid-auto-columns: 20px;
  grid-auto-rows: 20px;
}

.hh5 {
  font-size: 80px;
  z-index: 1;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 60;
}

form {
  z-index: 1;
  grid-row-start: 10;
  grid-column-start: 15;
  grid-column-end: 45;
  font-family: "Homenaje", sans-serif;
  font-size: 30px;
  line-height: 39px;
  text-align: left;
  margin-top: 20px;
}

.form-item {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-item-hopes {
  flex-direction: column;
}

input {
  border-radius: 8px;
  border: none;
  filter: drop-shadow(4px 4px 0 rgba(248, 100, 70, 1));
  font-family: "Homenaje", sans-serif;
  font-size: 20px;
  height: 40px;
  width: 100%;
  background-color: #f4e3c6;
}

.fixed-label-size {
  width: 150px;
}

textarea {
  border-radius: 8px;
  border: none;
  filter: drop-shadow(4px 4px 0 rgba(248, 100, 70, 1));
  height: 100px;
  width: 100%;
  font-family: "Homenaje", sans-serif;
  font-size: 20px;
  background-color: #f4e3c6;
}

.contact-select {
  border-radius: 8px;
  border: none;
  filter: drop-shadow(4px 4px 0 rgba(248, 100, 70, 1));
  height: 40px;
  font-family: "Homenaje", sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
}

.submit-button {
  z-index: 1;
  margin-left: 415px;
}

.submit-button-text {
  font-family: "Kodchasan", sans-serif;
  font-size: 30px;
  color: #2eaa87;
  text-shadow: 0px 4px 4px rgba(38, 44, 101, 0.25);
  background-color: #f4e3c6;
  padding: 20px;
  border-radius: 8px;
  border: none;
  filter: drop-shadow(4px 4px 4px rgba(255, 33, 58, 0.75));
  z-index: 3;
  font-weight: 700;
  width: 200px;
  transition: 0.3s;
  cursor: pointer;
  margin-top: 20px;
}

.submit-button-text:hover {
  color: #f4e3c6;
  background-color: rgba(255, 69, 60, 0.2);
  filter: drop-shadow(0px 4px 4px rgba(248, 100, 70, 1));
  transition: 0.3s;
}

.thank-you {
  background-color: #402649;
  color: #f4e3c6;
  z-index: 4;
  grid-row-start: 10;
  grid-row-end: 45;
  grid-column-start: 14;
  grid-column-end: 46;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.thank-you-image {
  height: 300px;
  width: 300px;
  z-index: 5;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .contact {
    width: 900px;
  }

  .hh5 {
    grid-column-end: 45;
  }

  form {
    grid-row-start: 10;
    grid-column-start: 6;
    grid-column-end: 40;
  }

  .submit-button {
    margin-left: 490px;
  }

  .thank-you {
    grid-row-start: 10;
    grid-row-end: 45;
    grid-column-start: 5;
    grid-column-end: 41;
  }
}
@media (max-width: 900px) {
  .contact {
    width: 600px;
  }

  .hh5 {
    font-size: 60px;
    grid-column-end: 30;
    grid-column-start: 2;
  }

  form {
    grid-row-start: 8;
    grid-column-start: 2;
    grid-column-end: 29;
  }

  .submit-button {
    margin-left: 350px;
  }

  .thank-you {
    grid-row-start: 8;
    grid-column-start: 1;
    grid-column-end: 31;
  }
}
@media (max-width: 600px) {
  .contact {
    width: 300px;
    height: 725px;
  }

  .hh5 {
    font-size: 40px;
    grid-column-end: 15;
    grid-column-start: 2;
    grid-row-start: 4;
  }

  form {
    grid-column-start: 2;
    grid-column-end: 15;
    grid-row-start: 10;
    grid-row-end: 30;
    font-size: 20px;
  }

  .submit-button {
    margin-left: 60px;
  }

  .submit-button-text {
    font-size: 20px;

    padding: 10px;

    width: 150px;
  }

  .thank-you {
    grid-column-start: 1;
    grid-column-end: 16;
    grid-row-start: 7;
    grid-row-end: 36;
  }

  .form-item {
    gap: 10px;
  }

  .fixed-label-size {
    width: 100px;
  }

  .thank-you-image {
    height: 260px;
    width: 260px;
  }
}
