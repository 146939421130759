.tours {
  z-index: 6;
  width: 100%;
  height: fit-content;
}

.full {
  color: #f86446;
}

.example-tour {
  max-width: 1200px;
  margin: 200px auto 0 auto;
}

.text-block {
  max-width: 600px;
  margin: 0 auto;
}

.tourpics {
  border-radius: 20px;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.8));
  transition: transform 0.5s ease-out;
}

.tourpics:hover {
  transform: rotate(5deg);
}

.tour-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 50px;
}

.tour-grid > div:nth-child(1) {
  grid-column: 2/5;
  grid-row: 2;
}

.tour-grid > img:nth-child(2) {
  grid-column: 1;
  grid-row: 13;
  rotate: 8deg;
}

.tour-grid > img:nth-child(3) {
  grid-column: 1;
  grid-row: 21;
  rotate: -8deg;
}

.tour-grid > img:nth-child(4) {
  grid-column: 1;
  grid-row: 24;
  rotate: 8deg;
}

.tour-grid > img:nth-child(5) {
  grid-column: 5;
  grid-row: 1;
  rotate: -5deg;
}

.tour-grid > img:nth-child(6) {
  grid-column: 1;
  grid-row: 1;
  rotate: 5deg;
}

.tour-grid > img:nth-child(7) {
  grid-column: 1;
  grid-row: 30;
  rotate: -5deg;
}

.tour-grid > img:nth-child(8) {
  grid-column: 1;
  grid-row: 9;
  rotate: -7deg;
}

.tour-grid > img:nth-child(9) {
  grid-column: 5;
  grid-row: 20;
  rotate: -13deg;
}

.tour-grid > img:nth-child(10) {
  grid-column: 2/5;
  grid-row: 45;
}

.tour-grid > img:nth-child(11) {
  grid-column: 5;
  grid-row: 4;
  rotate: 15deg;
}

.tour-grid > img:nth-child(12) {
  grid-column: 5;
  grid-row: 7;
  rotate: -5deg;
}

.tour-grid > img:nth-child(13) {
  grid-column: 5;
  grid-row: 28;
  rotate: 5deg;
}

.tour-grid > img:nth-child(14) {
  grid-column: 5;
  grid-row: 32;
  rotate: -5deg;
}

.tour-grid > img:nth-child(15) {
  grid-column: 5;
  grid-row: 10;
  rotate: 5deg;
}

.tour-grid > img:nth-child(16) {
  grid-column: 5;
  grid-row: 39;
  rotate: 9deg;
}

.tour-grid > img:nth-child(17) {
  grid-column: 1;
  grid-row: 36;
  rotate: -10deg;
}

@media (max-width: 900px) {
  .text-block {
    max-width: 400px;
  }
  .tour-grid > img:nth-child(10) {
    grid-column: 2/5;
    grid-row: 58;
  }
}

@media (max-width: 700px) {
  .tour-grid > img:nth-child(10) {
    grid-row: 57;
  }
}

@media (max-width: 500px) {
  .tour-grid > img:nth-child(10) {
    grid-row: 88;
  }
  .text-block {
    max-width: 200px;
  }
}

@media (max-width: 400px) {
  .example-tour {
    margin: 100px auto 0 auto;
  }
}
