.App-header {
  min-height: 100vh;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f4e3c6;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  padding-bottom: 50px 0;

  background-image: url(../images/happyBasinsBackgroundTop.png);
  background-size: cover;
  background-color: rgba(4, 4, 2, 1);
  background-attachment: fixed;
  background-position: center;
}

.header-box {
  border-radius: 50px;
  border: 10px solid #f4e3c6;
  background: rgba(48, 114, 97, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 80%;
  max-width: 1200px;
  padding-bottom: 200px;
  min-height: 100px;
  margin-top: 100px;
  margin-bottom: 250px;
}

.the-snake {
  width: 199.625px;
  height: 150px;
  transform: rotate(10.933deg);
  margin-left: 30px;
  margin-top: -60px;
}

@media (max-width: 600px) {
  .header-box {
    margin-bottom: 100px;
  }
}
