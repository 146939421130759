.mission-box {
  max-width: 900px;
  z-index: 5;
  position: relative;
  margin: 0 auto;
}

.mission {
  border-radius: 20px;
  background-color: rgba(48, 114, 97, 0.5);
}

.offset-title {
  position: relative;
  top: -60px;
  left: 20px;
  text-align: left;
  margin-bottom: -60px;
}

@media (max-width: 700px) {
  .offset-title {
    position: relative;
    top: -40px;
    left: 20px;
    text-align: left;
    margin-bottom: -40px;
  }
}

@media (max-width: 400px) {
  .mission-box {
    max-width: 250px;
  }

  .offset-title {
    position: relative;
    top: -40px;
    left: 20px;
    text-align: left;
    margin-bottom: -40px;
  }
}
