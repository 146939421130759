.footer {
  height: 200px;
  background-color: #f38e44;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-icon {
  filter: invert(38%) sepia(11%) saturate(1892%) hue-rotate(114deg)
    brightness(95%) contrast(89%) drop-shadow(2px 2px 0 rgba(248, 100, 70, 0.8));
  height: 150px;
}

.footer-icon:hover {
  filter: invert(58%) sepia(11%) saturate(2033%) hue-rotate(112deg)
    brightness(94%) contrast(96%) drop-shadow(2px 2px 0 rgba(248, 100, 70, 0.8));
  cursor: pointer;
}

.social-icon {
  height: 50px;
  filter: invert(38%) sepia(11%) saturate(1892%) hue-rotate(114deg)
    brightness(95%) contrast(89%) drop-shadow(2px 2px 0 rgba(248, 100, 70, 0.8));
  cursor: pointer;
}

.social-icon:hover {
  filter: invert(58%) sepia(11%) saturate(2033%) hue-rotate(112deg)
    brightness(94%) contrast(96%) drop-shadow(2px 2px 0 rgba(248, 100, 70, 0.8));
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  width: 110px;
  gap: 10px;
}

@media (max-width: 450px) {
  .footer {
    height: 500px;
    background-color: #f38e44;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
}
